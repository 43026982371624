<template>
  <v-app>
    <v-app-bar app color="primary" dark> Work Calendar generator </v-app-bar>

    <v-main>
      <!-- :event-color="date => date[9] % 2 ? 'red' : 'yellow'" -->
      <v-row justify="center">
        <v-date-picker
          first-day-of-week="1"
          full-width
          multiple
          :events="funcEvents"
          v-model="picker"
        ></v-date-picker>
      </v-row>
      <v-row justify="space-around" dense>
        <v-btn
          v-for="(shift, name) in shifts"
          :key="name"
          rounded
          large
          :color="shift['color']"
          v-on:click="addEvent(name)"
        >
        {{ shift.summary  }}  <br>
        {{ shift.startTime }}-{{ shift.endTime }}
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn rounded x-large color="green" v-on:click="createCal"
          >Generate</v-btn
        >
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";

export default {
  name: "App",

  components: {},

  data() {
    return {
      picker: [],
      events: {},
      shifts: {},
    };
  },

  mounted() {
    this.load_config();
  },
  methods: {
    async load_config() {
      const resp = await fetch("/conf.json");
      const data = await resp.json();
      this.shifts = data;
    },
    generateICS() {
      let events = [];
      let uidcount = Object.keys(this.events).length * 42;
      for (const key in this.events) {
        uidcount += 1;
        const datum = key.substring(key.length - 10);
        const start = this.events[key].startTime;
        let startDateTime = `${datum} ${start}`;
        startDateTime = moment(startDateTime)
          .utc()
          .format("YYYYMMDDTHHmmss[Z]");
        const end = this.events[key].endTime;
        let endDateTime = `${datum} ${end}`;
        endDateTime = moment(endDateTime).utc().format("YYYYMMDDTHHmmss[Z]");
        let createDateTime = moment().utc().format("YYYYMMDDTHHmmss[Z]");
        let uid = `${createDateTime}-${uidcount}@sandywork.deltgen.net`;
        let summary = `${this.events[key].summary}`;
        let event =
          "BEGIN:VEVENT\n" +
          `SUMMARY:${summary}\n` +
          `DTSTART:${startDateTime}\n` +
          `DTEND:${endDateTime}\n` +
          `DTSTAMP:${createDateTime}\n` +
          `UID:${uid}\n` +
          `END:VEVENT\n`;
        events.push(event);
      }
      return events;
    },
    addEvent(event) {
      this.picker.forEach((item) => {
        const key = `${event}-${item}`;
        if (key in this.events) {
          delete this.events[key];
        } else {
          this.events[key] = this.shifts[event];
        }
      });
      this.picker = [];
    },
    funcEvents(date) {
      const color = [];
      for (const key in this.events) {
        const datum = key.substring(key.length - 10);
        if (datum == date) {
          color.push(this.events[key].color);
        }
      }
      return color;
    },
    createCal() {
      let cal = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:Workshifts\n${this.generateICS().join(
        ""
      )}END:VCALENDAR`;
      this.download(cal, "wokshift.ics", "text/calendar;encoding:utf-8");
    },
    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    download(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },
  },
};
</script>
