import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import ICS from 'vue-ics'

Vue.config.productionTip = false
// Vue.use(ICS)


new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
